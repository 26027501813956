import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import Loading from "./loading";
import Sidebar from "./sidebar-categories";
import LazyLoad from "react-lazyload";
import placeholderImage from "../images/woocommerce-placeholder-300x300.png";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  row-gap: 20px;
  min-height: 90vh;
`;

const ProductCard = styled.div`
  flex: 1 0 33%;
  padding: 10px;
  box-sizing: border-box;
  max-width: 100%;

  @media (max-width: 768px) {
    flex: 1 0 50%;
  }

  @media (max-width: 480px) {
    flex: 1 0 100%;
    max-width: 100%;
  }
`;

const Card = styled.div`
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: 300px;
  margin-bottom: 10px;
  object-fit: contain;
`;

const ProductName = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-top: auto;
`;

const PaginationDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? "#8E0E01" : "#D51603")};
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #8e0e01;
  }

  &:focus {
    outline: none;
  }
`;

const Content = styled.div`
  display: flex;
  min-height: 90svh;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ProductsGridCategory = ({
  slug,
  currentPage,
  productsCache,
  setProductsCache,
  totalPages,
  handlePageChange,
}) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const cacheKey = `${slug}-${currentPage}`;
      if (productsCache[cacheKey]) {
        setProducts(productsCache[cacheKey]);
        setIsLoading(false);
        return;
      }

      try {
        const queryParams = new URLSearchParams({
          slug: slug,
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        });
        const categoryIdResponse = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products/categories?${queryParams}`
        );

        if (!categoryIdResponse.ok) {
          console.error("Error status:", categoryIdResponse.status);
          throw new Error(
            `Failed to fetch category: ${categoryIdResponse.statusText}`
          );
        }

        const categories = await categoryIdResponse.json();
        if (categories.length === 0) throw new Error("Category not found");
        const categoryId = categories[0].id;

        const productParams = new URLSearchParams({
          category: categoryId.toString(),
          per_page: "6",
          page: currentPage.toString(),
          status: "publish",
          _fields: "id,slug,name,images",
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        });
        const productsResponse = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products?${productParams}`
        );

        if (!productsResponse.ok) throw new Error("Products not found");

        const products = await productsResponse.json();
        setProducts(products);
        setProductsCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: products,
        }));
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug, currentPage, productsCache, setProductsCache]);

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error}</div>;
  if (!products.length) return <div>No products found in this category</div>;

  return (
    <div>
      <Grid>
        {products.map((product) => (
          <ProductCard key={product.id}>
            <Card>
              <Link to={`/product/${product.slug}`}>
                <LazyLoad
                  height={300}
                  offset={100}
                  placeholder={
                    <ProductImage src={placeholderImage} alt="placeholder" />
                  }
                >
                  <ProductImage
                    src={
                      product.images[0]
                        ? product.images[0].src
                        : placeholderImage
                    }
                    alt={product.name}
                    loading="lazy"
                  />
                </LazyLoad>
                <ProductName>{product.name}</ProductName>
              </Link>
            </Card>
          </ProductCard>
        ))}
      </Grid>
      {totalPages > 1 && (
        <PaginationDiv>
          {[...Array(totalPages).keys()].map((number) => (
            <PageButton
              key={number + 1}
              onClick={() => handlePageChange(number + 1)}
              isActive={currentPage === number + 1}
            >
              {number + 1}
            </PageButton>
          ))}
        </PaginationDiv>
      )}
    </div>
  );
};

const CategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [productsCache, setProductsCache] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    setCurrentPage(1);
  }, [slug]);

  const fetchTotalPages = useCallback(async () => {
    try {
      const categoryIdParams = new URLSearchParams({
        slug: slug,
        consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
        consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
      });
      const categoryIdResponse = await fetch(
        `https://test.mangi.org/wordpress/wp-json/wc/v3/products/categories?${categoryIdParams}`
      );

      if (!categoryIdResponse.ok) throw new Error("Category not found");
      const categories = await categoryIdResponse.json();
      if (categories.length === 0) throw new Error("Category not found");
      const categoryId = categories[0].id;

      const paginationParams = new URLSearchParams({
        category: categoryId.toString(),
        per_page: "6",
        page: "1",
        status: "publish",
        consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
        consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
      });
      const response = await fetch(
        `https://test.mangi.org/wordpress/wp-json/wc/v3/products?${paginationParams}`
      );

      if (!response.ok)
        throw new Error("Failed to fetch products for pagination");

      const totalPages = response.headers.get("X-WP-TotalPages");
      setTotalPages(parseInt(totalPages, 10));
    } catch (error) {
      console.error("Error fetching total pages:", error);
    }
  }, [slug]);

  useEffect(() => {
    fetchTotalPages();
  }, [fetchTotalPages]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const fetchAndCacheRemainingPages = useCallback(async () => {
    const fetchPage = async (page) => {
      const cacheKey = `${slug}-${page}`;
      if (productsCache[cacheKey]) {
        return;
      }

      try {
        const queryParams = new URLSearchParams({
          slug: slug,
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        });
        const categoryIdResponse = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products/categories?${queryParams}`
        );

        if (!categoryIdResponse.ok) {
          throw new Error(
            `Failed to fetch category: ${categoryIdResponse.statusText}`
          );
        }

        const categories = await categoryIdResponse.json();
        if (categories.length === 0) throw new Error("Category not found");
        const categoryId = categories[0].id;

        const productParams = new URLSearchParams({
          category: categoryId.toString(),
          per_page: "6",
          page: page.toString(),
          status: "publish",
          _fields: "id,slug,name,images",
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        });
        const productsResponse = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products?${productParams}`
        );

        if (!productsResponse.ok) throw new Error("Products not found");

        const products = await productsResponse.json();
        setProductsCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: products,
        }));
      } catch (err) {
        console.error(err.message);
      }
    };

    const pagePromises = [];
    for (let page = 2; page <= totalPages; page++) {
      pagePromises.push(fetchPage(page));
    }

    await Promise.all(pagePromises);
  }, [slug, totalPages, productsCache, setProductsCache]);

  useEffect(() => {
    if (totalPages > 1) {
      fetchAndCacheRemainingPages();
    }
  }, [totalPages, fetchAndCacheRemainingPages]);

  return (
    <div>
      <Content>
        <Sidebar />
        <ProductsGridCategory
          slug={slug}
          currentPage={currentPage}
          productsCache={productsCache}
          setProductsCache={setProductsCache}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Content>
    </div>
  );
};

export default CategoryPage;
